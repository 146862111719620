<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Application discussion category</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Application discussion category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createSupportCategory()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                  >
                    <i class="fa fa-plus"></i>
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="12" md="5">
                  <v-text-field
                      label="Name"
                      v-model="search.info"
                      outlined
                      dense
                      v-on:keyup.enter="searchSupportCategory()"
                      @input="search.info = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="5">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchSupportCategory()"
                      @input="search.is_active = $event !== null ? $event : ''"
                      dense 
                      clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-btn
                      @click="searchSupportCategory"
                      class="mt-1 text-white btn btn-primary"
                      :loading="isLoading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! isLoading">
                <thead>
                <tr class="px-3">
                  <th><strong>Name</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <draggable
                      v-model="discussionCategories"
                      @change="sort"
                      @start="drag=true"
                      tag="tbody"
                      @end="drag=false"
                  >
                    <tr v-for="(item, index) in discussionCategories" :key="index">
                      <td>
                        <span class="text-primary ml-2 font-weight-bolder d-block font-size-lg">
                        <i class="fa fa-sort"></i> &nbsp;{{ item.name }}
                      </span>
                      </td>

                      <td>
                           <span class="badge badge-success text-lg`"
                                 v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                           >{{ item.is_active ? 'Active' : 'InActive'}}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editSupportCategory(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteSupportCategory(item.id)">
                                  <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="discussionCategories.length == 0">
                      <td class="text-center px-3" colspan="4">No items found.</td>
                    </tr>
                  </draggable>
                </template>

              </table>

              <b-pagination
                  v-if="discussionCategories.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSupportCategory"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="isLoading"
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </div>

      <create-or-update ref="create-or-update" @refresh="getAllSupportCategory"></create-or-update>

    </div>
  </v-app>
</template>

<script>
import ApplicationDiscussionCategoryService from "@/services/application/discussion/category/ApplicationDiscussionCategoryService";
import CreateOrUpdate from "./CreateOrUpdate";
import draggable from 'vuedraggable'
const supportCategory=new ApplicationDiscussionCategoryService();
export default {
  components:{
    CreateOrUpdate,
   draggable
  },
  data(){
    return{
      search:{
        info:'',
        is_active:'',
        score_id:'',
      },
      status: [

        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      discussionCategories:[],
      total: null,
      perPage: null,
      page: null,
      isSorting: false,
      isLoading: false,
    }
  },
  methods:{
    getAllSupportCategory(){
      this.isLoading = true;
      supportCategory
          .paginate(this.search, this.page)
          .then(response => {
            this.discussionCategories=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
            this.$store.dispatch('GET_DISCUSSION_CATEGORIES')
          })
          .catch(() => {});
    },
    searchSupportCategory(){
      this.getAllSupportCategory();
    },
    createSupportCategory(){
      this.$refs['create-or-update'].createSupportCategory();
    },
    editSupportCategory(item){
      this.$refs['create-or-update'].editSupportCategory(item);
    },
    deleteSupportCategory(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            supportCategory
                .delete(item)
                .then((response) => {
                  this.getAllSupportCategory();
                  this.$snotify.success("Application Discussion Category Successfully Deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    sort(){
      this.isSorting = true;
      supportCategory
          .sort(this.discussionCategories)
          .then(response => {
            this.getAllSupportCategory();
            this.isSorting = false;
            this.$snotify.success('Application Discussion Category!!');
          })
          .catch(err => {

          });
    }
  },
  mounted() {
    this.getAllSupportCategory();
  }
}
</script>