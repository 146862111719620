<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Application Discussion Category</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  label="Name*"
                  outlined :error="$v.support_category.name.$error"
                  dense
                  v-model="support_category.name">
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.support_category.name.$error">This Name Field is Required</span>
              <span class="text-danger" v-if="errors.name" >**{{errors.name[0]}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <ckeditor
                  :config="editorConfig"
                  v-model="support_category.description" >
              </ckeditor>
            </v-col>
            <v-col cols="6" md="4">
              <span class="font-weight-medium">Status</span>
              <v-switch
                  v-model="support_category.is_active"
                  :label="support_category.is_active ?'Active':'Inactive'"

                  hide-details></v-switch>
              <span class="text-danger" v-if="errors.is_active" >**{{errors.is_active[0]}}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ApplicationDiscussionCategoryService from "@/services/application/discussion/category/ApplicationDiscussionCategoryService";
import {required, numeric, requiredIf} from "vuelidate/lib/validators";
const supportCategory=new ApplicationDiscussionCategoryService();
export default {
  validations:{
    support_category:{
      name:{required},
    },
  },
  data(){
    return{
      title:'',
      dialog: false,
      edit: false,
      loading: false,
      errors:[],
      scores:[],
      support_category:{
        name:'',
        description:'',
        position:'',
        is_active:true
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
    }
  },
  methods:{
    back() {
      this.$router.push({
        name: "category",
      });
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset();
      this.$emit('refresh');
    },
    resetForm(){
      this.support_category={
        name:'',
        description:'',
        position:'',
        is_active:true,
      };
    },
    openDialog() {
      this.dialog = true;
    },
    createSupportCategory() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
    },
    editSupportCategory(item) {
      this.openDialog();
      this.edit = true;
      this.support_category = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      supportCategory
          .create(this.support_category)
          .then(response => {
            this.$snotify.success("Application Discussion Category Text created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      supportCategory
          .update(this.support_category.id, this.support_category)
          .then(response => {
            this.$snotify.success("Application Discussion Category Text updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })

    }
  }
}
</script>
